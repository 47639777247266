import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const calendar = () => (
    <Layout>
      <SEO title="Calendar" />
   
      <p><br></br></p><p><br></br></p><p><br></br></p>
<div className="mainContent">
    <p className="center">
    <div className="responsive">
    <iframe src="https://calendar.google.com/calendar/b/2/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=Ym9iY2F0c25vdmFAZ21haWwuY29t&amp;color=%23023c89&amp;title=BOBCAT%20SEASON%20SCHEDULE&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;showNav=1&amp;showDate=1" width="800" height="500" frameborder="0" scrolling="no"></iframe>		
</div></p>
</div>
    </Layout>
  )
  
  export default calendar